import axios from "axios";
import env from "@/config/env";

axios.defaults.withCredentials = true;
const SLID_SERVER_URL = env.end_point_url.slid_api;

/* deciding api endpoints */
let users_endpoint = `${SLID_SERVER_URL}/v6/users/`;
const user_exists_endpoint = `${SLID_SERVER_URL}/v7/user-exists/`;

export const ServerInterface_getNotifications = async ({ accessToken }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let notificationsData = {};
  try {
    const notificationsResponse = await axios.get(`${SLID_SERVER_URL}/v6/notification/`, config);
    notificationsData = notificationsResponse.data;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          notificationsData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "NOTIFICATION_NOT_FOUND";
          notificationsData.error_message = "NOTIFICATION_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          notificationsData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_NOTIFICATIONS_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting notifications from server",
          };
          notificationsData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        notificationsData.error_message = "NETWORK_ERROR";
      }
    }
  } finally {
    return notificationsData;
  }
};

export const ServerInterface_markNotificationsAsViewed = async ({ accessToken, notificationsData }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const payload = notificationsData;
  let result = null;
  try {
    const notificationsPutResponse = await axios.put(`${SLID_SERVER_URL}/v6/notification/`, payload, config);
    result = notificationsPutResponse.data;
  } catch (error) {
    console.error(error);
    if (!result) result = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          result.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "USER_AUTH_FAIL";
          result.error_message = "USER_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          result.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_PUT_NOTIFICATIONS_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          result.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        result.error_message = "NETWORK_ERROR";
      }
    }
  } finally {
    return result;
  }
};

export const ServerInterface_getDocuments = async ({ accessToken, documentKey, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let lecturesData = null;
  try {
    const lecturesGetResponse = await axios.get(`${SLID_SERVER_URL}/v6/documents/${documentKey ? documentKey + "/" : ""}`, config);
    lecturesData = lecturesGetResponse.data;
  } catch (error) {
    console.error(error);

    if (!lecturesData) lecturesData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          lecturesData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "DOCUMENT_NOT_FOUND";
          lecturesData.error_message = "DOCUMENT_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          lecturesData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_DOCUMENTS_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting documents from server",
          };
          lecturesData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        lecturesData.error_message = "NETWORK_ERROR";
      }
    }

    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return lecturesData;
  }
};

export const ServerInterface_getDeletedDocuments = async ({ accessToken, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let deletedDocumentsData = null;
  try {
    const deletedDocumentsGetResponse = await axios.get(`${SLID_SERVER_URL}/v6/deleted-documents/`, config);
    deletedDocumentsData = deletedDocumentsGetResponse.data;
  } catch (error) {
    console.error(error);

    if (!deletedDocumentsData) deletedDocumentsData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          error.custom_message = "DOCUMENT_NOT_FOUND";
          error.additionalMessage = {
            message: "there are no document for given id",
          };
          deletedDocumentsData.error_message = "DOCUMENT_NOT_FOUND";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_DELETED_DOCUMENTS_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting deleted documents from server",
          };
          deletedDocumentsData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        deletedDocumentsData.error_message = "NETWORK_ERROR";
      }
    }

    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return deletedDocumentsData;
  }
};

export const ServerInterface_getDocument = async ({ accessToken, documentKey, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let documentData = null;
  try {
    const documentGetResponse = await axios.get(`${SLID_SERVER_URL}/v6/documents/${documentKey}/`, config);
    documentData = documentGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "DOCUMENT_NOT_FOUND";
          error.additionalMessage = {
            message: "there are no document for given id",
          };
          documentData.error_message = "DOCUMENT_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_DOCUMENT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting document from server",
            documentKey: documentKey,
          };
          documentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        documentData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return documentData;
  }
};

export const ServerInterface_getShareDocument = async ({ documentKey, serverErrorHandler }) => {
  let documentData = null;
  try {
    const documentGetResponse = await axios.get(`${SLID_SERVER_URL}/v6/share/${documentKey}/`);
    documentData = documentGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "DOCUMENT_NOT_PUBLIC";
          error.additionalMessage = {
            message: "there are no document for given id",
          };
          documentData.error_message = "DOCUMENT_NOT_PUBLIC";
          break;
        case 404:
          if (error.response.data.message === "Document Not Public") {
            error.custom_message = "DOCUMENT_NOT_PUBLIC";
            error.additionalMessage = {
              message: "there are no document for given id",
            };
            documentData.error_message = "DOCUMENT_NOT_PUBLIC";
          } else {
            error.custom_message = "DOCUMENT_NOT_FOUND";
            error.additionalMessage = {
              message: "there are no document for given id",
            };
            documentData.error_message = "DOCUMENT_NOT_FOUND";
          }
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_SHARE_DOCUMENT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting share document from server",
            documentKey: documentKey,
          };
          documentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        documentData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return documentData;
  }
};

export const ServerInterface_getDocumentHistoryList = async ({ accessToken, documentKey, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let documentData = null;
  let documentPostResponse = null;

  try {
    documentPostResponse = await axios.get(`${SLID_SERVER_URL}/v6/history/${documentKey}/`, config);

    documentData = documentPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "DOCUMENT_NOT_FOUND";
          error.additionalMessage = {
            message: "there are no document for given id",
          };
          documentData.error_message = "DOCUMENT_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_DOCUMENT_HISTORY_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting document history from server",
            documentKey: documentKey,
          };
          documentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        documentData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return documentData;
  }
};

export const ServerInterface_createDocumentHistory = async ({ accessToken, documentKey, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const payload = {
    document_key: documentKey,
  };

  let documentData = null;

  try {
    const documentPostResponse = await axios.post(`${SLID_SERVER_URL}/v6/history/${documentKey}/`, payload, config);
    documentData = documentPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_CREATE_DOCUMENT_HISTORY_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          documentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        documentData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return documentData;
  }
};

export const ServerInterface_deleteDocument = async ({ accessToken, documentKey, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const payload = { document_key: documentKey, is_deleted: 1 };

  let documentDeleteData = null;
  try {
    const documentDeleteResponse = await axios.patch(`${SLID_SERVER_URL}/v6/documents/${documentKey}/`, payload, config);
    documentDeleteData = documentDeleteResponse.data;
  } catch (error) {
    console.error(error);

    if (!documentDeleteData) documentDeleteData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          documentDeleteData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "VIDEO_NOT_FOUND";
          error.additionalMessage = {
            message: "there are no video for given id",
          };
          documentDeleteData.error_message = "VIDEO_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          documentDeleteData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_VIDEO_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting slides of the video from server",
            documentKey: documentKey,
          };
          documentDeleteData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return documentDeleteData;
  }
};

export const ServerInterface_restoreDocument = async ({ accessToken, documentKey, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const payload = { document_key: documentKey, is_deleted: 0 };

  let documentData = null;
  try {
    const documentResponse = await axios.patch(`${SLID_SERVER_URL}/v6/documents/${documentKey}/`, payload, config);
    documentData = documentResponse.data;
  } catch (error) {
    console.error(error);

    if (!documentData) documentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "DOCUMENT_NOT_FOUND";
          error.additionalMessage = {
            message: "there are no document for given id",
          };
          documentData.error_message = "DOCUMENT_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_RESTORE_DOCUMENT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error restore document from server",
            documentKey: documentKey,
          };
          documentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return documentData;
  }
};

export const ServerInterface_deleteDocumentPermanently = async ({ accessToken, documentKey, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const payload = { document_key: documentKey, is_deleted: 2 };

  let documentData = null;
  try {
    const documentResponse = await axios.patch(`${SLID_SERVER_URL}/v6/documents/${documentKey}/`, payload, config);
    documentData = documentResponse.data;
  } catch (error) {
    console.error(error);

    if (!documentData) documentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "DOCUMENT_NOT_FOUND";
          error.additionalMessage = {
            message: "there are no document for given id",
          };
          documentData.error_message = "DOCUMENT_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_PERMANENTLY_DELETE_DOCUMENT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error delete document from server permanently",
            documentKey: documentKey,
          };
          documentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return documentData;
  }
};

export const ServerInterface_createDocument = async ({ accessToken, data, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const payload = data;

  let documentData = null;

  try {
    const documentPostResponse = await axios.post(`${SLID_SERVER_URL}/v6/documents/`, payload, config);
    documentData = documentPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_POST_DOCUMENT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          documentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        documentData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return documentData;
  }
};

export const ServerInterface_updateDocument = async ({ accessToken, documentKey, data, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const payload = data;
  let documentData = null;
  try {
    const lecturePutResponse = await axios.put(`${SLID_SERVER_URL}/v6/documents/${documentKey}/`, payload, config);
    documentData = lecturePutResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "DOCUMENT_NOT_FOUND";
          error.additionalMessage = {
            message: "there are no video for given id",
          };
          documentData.error_message = "DOCUMENT_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_UPDATE_VIDEO_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error updating document from server",
            documentKey: documentKey,
          };
          documentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        documentData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return documentData;
  }
};

export const ServerInterface_searchDocument = async ({ accessToken, data, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let documentData = null;
  try {
    const documentGetResponse = await axios.get(`${SLID_SERVER_URL}/v6/search/?category=document&target=title_content&keyword=${data.keyword}`, config);
    documentData = documentGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "DOCUMENT_NOT_FOUND";
          error.additionalMessage = {
            message: "there are no document for given id",
          };
          documentData.error_message = "DOCUMENT_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          documentData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_SEARCH_DOCUMENT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error searching documents from server",
          };
          documentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        documentData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return documentData;
  }
};

export const ServerInterface_registerVideoToDocument = async ({ accessToken, data, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const payload = data;

  let videoRegisterData = null;

  try {
    const videoPostResponse = await axios.post(`${SLID_SERVER_URL}/v6/videos/`, payload, config);
    videoRegisterData = videoPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!videoRegisterData) videoRegisterData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          videoRegisterData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "USER_AUTH_FAIL";
          videoRegisterData.error_message = "USER_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          videoRegisterData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_POST_VIDEO_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          videoRegisterData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        videoRegisterData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return videoRegisterData;
  }
};

export const ServerInterface_updateVideo = async ({ accessToken, videoKey, data, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const payload = data;
  let videoUpdateData = null;
  try {
    const videoUpdateResponse = await axios.put(`${SLID_SERVER_URL}/v6/videos/${videoKey}/`, payload, config);
    videoUpdateData = videoUpdateResponse.data;
  } catch (error) {
    console.error(error);
    if (!videoUpdateData) videoUpdateData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          videoUpdateData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.sentry_message = "SLID_WEB_UPDATE_VIDEO_NOT_FOUND";
          error.level = "error";
          error.custom_message = "VIDEO_NOT_FOUND";
          error.additionalMessage = {
            message: "there are no video for given video key",
          };
          videoUpdateData.error_message = "VIDEO_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          videoUpdateData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_UPDATE_VIDEO_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error updating video from server",
          };
          videoUpdateData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        videoUpdateData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return videoUpdateData;
  }
};

export const ServerInterface_requestPdfUpload = async ({ accessToken, data, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const payload = data;

  let pdfUploadRequestResult = null;

  try {
    const pdfUploadPostResponse = await axios.post(`${SLID_SERVER_URL}/v6/uploads/`, payload, config);
    pdfUploadRequestResult = pdfUploadPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!pdfUploadRequestResult) pdfUploadRequestResult = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          pdfUploadRequestResult.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          pdfUploadRequestResult.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_POST_UPLOAD_PDF_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          pdfUploadRequestResult.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        pdfUploadRequestResult.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return pdfUploadRequestResult;
  }
};

export const ServerInterface_getPdfUploadResults = async ({ accessToken, pdfKey, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let pdfUploadResultData = null;
  try {
    const pdfUploadResultGetResponse = await axios.get(`${SLID_SERVER_URL}/v6/uploads/${pdfKey}/`, config);
    pdfUploadResultData = pdfUploadResultGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!pdfUploadResultData) pdfUploadResultData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          pdfUploadResultData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "VIDEO_NOT_FOUND";
          pdfUploadResultData.error_message = "VIDEO_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          pdfUploadResultData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_PDF_UPLOAD_RESULT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          pdfUploadResultData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        pdfUploadResultData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return pdfUploadResultData;
  }
};

export const ServerInterface_getTrimmedClip = async ({ accessToken, data, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const payload = data;

  let trimmedClipData = null;

  try {
    const clipTrimPostResponse = await axios.post(`${SLID_SERVER_URL}/v6/clips/autotrim/`, payload, config);
    trimmedClipData = clipTrimPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!trimmedClipData) trimmedClipData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          trimmedClipData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "USER_AUTH_FAIL";
          trimmedClipData.error_message = "USER_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          trimmedClipData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_POST_CLIP_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          trimmedClipData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        trimmedClipData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return trimmedClipData;
  }
};

/**
 * imageSource is capture-clip(default) or upload-clip
 */
export const ServerInterface_registerClip = async ({ accessToken, data, serverErrorHandler, clipEndPoint = "upload-clip" }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const payload = data;

  let clipRegisterData = null;

  try {
    const clipPostResponse = await axios.post(`${SLID_SERVER_URL}/v6/${clipEndPoint}/`, payload, config);
    clipRegisterData = clipPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!clipRegisterData) clipRegisterData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          clipRegisterData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "USER_AUTH_FAIL";
          clipRegisterData.error_message = "USER_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          clipRegisterData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_POST_CLIP_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          clipRegisterData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        clipRegisterData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return clipRegisterData;
  }
};

/**
 * imageSource is capture-clip(default) or upload-clip
 */
export const ServerInterface_updateClip = async ({ accessToken, data, serverErrorHandler, clipEndPoint = "upload-clip" }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const payload = data;

  let clipUpdateData = null;

  try {
    const clipPutResponse = await axios.put(`${SLID_SERVER_URL}/v6/${clipEndPoint}/`, payload, config);
    clipUpdateData = clipPutResponse.data;
  } catch (error) {
    console.error(error);
    if (!clipUpdateData) clipUpdateData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          clipUpdateData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "USER_AUTH_FAIL";
          clipUpdateData.error_message = "USER_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          clipUpdateData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_PUT_CLIP_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          clipUpdateData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        clipUpdateData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return clipUpdateData;
  }
};

export const ServerInterface_deleteClip = async ({ accessToken, data, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let deleteClipData = null;
  try {
    const clipDeleteResponse = await axios.delete(`${SLID_SERVER_URL}/v6/clips/${data.clipKey}/`, config);
    deleteClipData = clipDeleteResponse.data;
  } catch (error) {
    console.error(error);
    if (!deleteClipData) deleteClipData = {};
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          deleteClipData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.sentry_message = "SLID_WEB_DELETE_CLIP_NOT_FOUND";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error deleting clip of video",
          };
          deleteClipData.error_message = "CLIP_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          deleteClipData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_DELETE_CLIP_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error deleting clip from server",
          };
          deleteClipData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return deleteClipData;
  }
};

export const ServerInterface_registerClipOCR = async ({ accessToken, data, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const payload = data;

  let clipOCRRegisterData = null;

  try {
    const clipOCRPostResponse = await axios.post(`${SLID_SERVER_URL}/v6/ocrs/`, payload, config);
    clipOCRRegisterData = clipOCRPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!clipOCRRegisterData) clipOCRRegisterData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          clipOCRRegisterData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "USER_AUTH_FAIL";
          clipOCRRegisterData.error_message = "USER_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          clipOCRRegisterData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_POST_CLIP_OCR_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          clipOCRRegisterData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        clipOCRRegisterData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return clipOCRRegisterData;
  }
};

export const ServerInterface_getClipOCRResults = async ({ accessToken, clip_key, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let clipOCRResultData = null;
  try {
    const clipOCRResultGetResponse = await axios.get(`${SLID_SERVER_URL}/v6/ocrs/${clip_key}/`, config);
    clipOCRResultData = clipOCRResultGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!clipOCRResultData) clipOCRResultData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          clipOCRResultData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "CLIP_NOT_FOUND";
          error.additionalMessage = {
            message: "there are no clip for given key",
          };
          clipOCRResultData.error_message = "CLIP_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          clipOCRResultData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_CLIP_OCR_RESULT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting clip OCR result from server",
          };
          clipOCRResultData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        clipOCRResultData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return clipOCRResultData;
  }
};

export const ServerInterface_moveToFolder = async ({ accessToken, parentKey, documentKeys, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const payload = {
    parent_key: parentKey,
    document_keys: documentKeys,
  };
  let moveToFolderData = null;
  try {
    const moveToFolderResponse = await axios.put(`${SLID_SERVER_URL}/v6/documents/`, payload, config);
    moveToFolderData = moveToFolderResponse.data;
  } catch (error) {
    console.error(error);
    if (!moveToFolderData) moveToFolderData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          moveToFolderData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.sentry_message = "SLID_WEB_UPDATE_FOLDER_NOT_FOUND";
          error.level = "info";
          error.custom_message = "DOCUMENT_NOT_FOUND";
          error.additionalMessage = {
            message: "there are no folder for given id",
          };
          moveToFolderData.error_message = "DOCUMENT_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          moveToFolderData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_UPDATE_VIDEO_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error moving to folder from server",
          };
          moveToFolderData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        moveToFolderData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return moveToFolderData;
  }
};

export const ServerInterface_getPayment = async ({ accessToken, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let paymentData = null;
  try {
    const paymentGetResponse = await axios.get(`${SLID_SERVER_URL}/v6/subscription-payments/`, config);
    paymentData = paymentGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!paymentData) paymentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          paymentData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          paymentData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_PAYMENT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting payment from server",
          };
          paymentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        paymentData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return paymentData;
  }
};

export const ServerInterface_createPayment = async ({ accessToken, data, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const payload = data;

  let createPaymentData = null;

  try {
    const createPaymentResponse = await axios.post(`${SLID_SERVER_URL}/v6/subscription-payments/`, payload, config);
    createPaymentData = createPaymentResponse.data;
  } catch (error) {
    console.error(error);
    if (!createPaymentData) createPaymentData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          createPaymentData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "USER_AUTH_FAIL";
          createPaymentData.error_message = "USER_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          createPaymentData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_POST_PAYMENT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          createPaymentData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        createPaymentData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return createPaymentData;
  }
};

export const ServerInterface_updateSubscription = async ({ accessToken, data, serverErrorHandler }) => {
  /*
  data = {
    subscription_key:"some_valid_key",
    is_active:true/false,
  }
  */
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const payload = data;
  let updateSubscriptionResponse = null;
  try {
    const updateSubscriptionRawResponse = await axios.put(`${SLID_SERVER_URL}/v6/subscription/`, payload, config);
    updateSubscriptionResponse = updateSubscriptionRawResponse.data;
  } catch (error) {
    if (!updateSubscriptionResponse) updateSubscriptionResponse = {};
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          error.custom_message = "MISSING_OR_INVALID_SUBSCRIPTION_KEY";
          updateSubscriptionResponse.error_message = "MISSING_OR_INVALID_SUBSCRIPTION_KEY";
          break;
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          updateSubscriptionResponse.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "USER_AUTH_FAIL";
          updateSubscriptionResponse.error_message = "USER_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          updateSubscriptionResponse.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_POST_PAYMENT_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          updateSubscriptionResponse.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        updateSubscriptionResponse.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return updateSubscriptionResponse;
  }
};

export const ServerInterface_getReferralCode = async ({ referralCode }) => {
  let referralCodeData = null;

  try {
    const referralCodeGetResponse = await axios.get(`${SLID_SERVER_URL}/v6/subscription-referrals?referral_code=${referralCode}`);
    referralCodeData = referralCodeGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!referralCodeData) referralCodeData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          referralCodeData.error_message = "REFERRAL_CODE_GET_ERROR";
          break;
        default:
          referralCodeData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        referralCodeData.error_message = "NETWORK_ERROR";
      }
    }
  } finally {
    return referralCodeData;
  }
};

/**
 * @param {accessToken} param
 * @returns {
    "user": number,
    "payment_type": "basic" or "starter",
    "quota_key": string,
    "capture_quota_remaining": number,
    "capture_quota_last_renew_time": timezone as string,
    "capture_quota_next_renew_time": timezone as string,
    "clip_record_quota_remaining": number,
    "clip_record_quota_last_renew_time": timezone as string,
    "clip_record_quota_next_renew_time": timezone as string
}
 */
export const ServerInterface_getUserQuotaData = async ({ accessToken }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let userQuotaData = {};
  try {
    const userQuotaDataResponse = await axios.get(`${SLID_SERVER_URL}/v6/quota/`, config);
    userQuotaData = userQuotaDataResponse.data;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          error.custom_message = "UNDEFINED_REQUEST";
          userQuotaData.error_message = "UNDEFINED_REQUEST";
          break;
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          userQuotaData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 403:
          error.custom_message = "MEMBERSHIP_IS_NOT_STARTER_OR_BASIC";
          userQuotaData.error_message = "MEMBERSHIP_IS_NOT_STARTER_OR_BASIC";
          break;
        case 404:
          error.custom_message = "QUATA_NOT_FOUND";
          userQuotaData.error_message = "QUATA_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          userQuotaData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_QUOTA_DATA_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting quota data from server",
          };
          userQuotaData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        userQuotaData.error_message = "NETWORK_ERROR";
      }
    }
  } finally {
    return userQuotaData;
  }
};

export const ServerInterface_deductClipRecordingQuota = async ({ accessToken, serverErrorHandler }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const payload = {};

  let deductClipRecordingData = null;

  try {
    const deductClipRecordingResponse = await axios.post(`${SLID_SERVER_URL}/v6/clip-recording/deduct-quota/`, payload, config);
    deductClipRecordingData = deductClipRecordingResponse.data;
  } catch (error) {
    console.error(error);
    if (!deductClipRecordingData) deductClipRecordingData = {};

    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          deductClipRecordingData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 403:
          error.custom_message = "MEMBERSHIP_IS_NOT_STARTER_OR_BASIC";
          deductClipRecordingData.error_message = "MEMBERSHIP_IS_NOT_STARTER_OR_BASIC";
          break;
        case 404:
          error.custom_message = "QUATA_NOT_FOUND";
          deductClipRecordingData.error_message = "QUATA_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          deductClipRecordingData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_DEDUCT_CLIP_RECORDING_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          deductClipRecordingData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        deductClipRecordingData.error_message = "NETWORK_ERROR";
      }
    }
    if (serverErrorHandler) serverErrorHandler(error);
  } finally {
    return deductClipRecordingData;
  }
};

/**
 * @param {accessToken} param
 * @returns {
    'subscription_key' : str,
    'payment_gateway' : str (paypal or payple),
    'is_active' : bool,
    'started_at' : timezome aware datetime as str,
    'last_billed_at' : timezome aware datetime as str,
    'next_billing_at' : timezome aware datetime as str,
    'subscription_months' : number,
    'payment_cnt' : number
}
 */
export const ServerInterface_getUserSubscriptionData = async ({ accessToken }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let userSubscriptionData = {};
  try {
    const userSubscriptionDataResponse = await axios.get(`${SLID_SERVER_URL}/v6/subscription`, config);
    userSubscriptionData = userSubscriptionDataResponse.data;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          error.custom_message = "UNDEFINED_REQUEST";
          userSubscriptionData.error_message = "UNDEFINED_REQUEST";
          break;
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          userSubscriptionData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "SUBSCRIPTION_NOT_FOUND";
          userSubscriptionData.error_message = "SUBSCRIPTION_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          userSubscriptionData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_SUBSCRIPTION_DATA_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting subscription data from server",
          };
          userSubscriptionData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        userSubscriptionData.error_message = "NETWORK_ERROR";
      }
    }
  } finally {
    return userSubscriptionData;
  }
};

export const ServerInterface_fetchVideos = async ({ accessToken }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  let userVideosData = {};
  try {
    const userVideosDataResponse = await axios.get(`${SLID_SERVER_URL}/v6/videos/`, config);
    userVideosData = userVideosDataResponse.data;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          error.custom_message = "UNDEFINED_REQUEST";
          userVideosData.error_message = "UNDEFINED_REQUEST";
          break;
        case 401:
          error.custom_message = "USER_AUTH_FAIL";
          userVideosData.error_message = "USER_TOKEN_EXPIRE";
          break;
        case 404:
          error.custom_message = "USER_VIDEOS_NOT_FOUND";
          userVideosData.error_message = "USER_VIDEOS_NOT_FOUND";
          break;
        case 422:
          error.custom_message = "USER_AUTH_FAIL";
          userVideosData.error_message = "USER_TOKEN_INVALID";
          break;
        default:
          error.sentry_message = "SLID_WEB_GET_USER_VIDEOS_DATA_ERROR";
          error.level = "error";
          error.custom_message = "SERVER_ERROR";
          error.additionalMessage = {
            message: "error getting user videos data from server",
          };
          userVideosData.error_message = "SERVER_ERROR";
      }
    } else {
      if (error.message === "Network Error") {
        error.sentry_message = "SLID_WEB_NETWORK_CONNECTION_LOST";
        error.level = "error";
        error.custom_message = "NETWORK_ERROR";
        userVideosData.error_message = "NETWORK_ERROR";
      }
    }
  } finally {
    return userVideosData;
  }
};

export const ServerInterface_isSlidUser = async ({ cognitoIdToken }) => {
  const config = {
    headers: {
      Authorization: `${cognitoIdToken}`,
    },
  };
  try {
    const response = await axios.get(user_exists_endpoint, config);
    const responseMessage = response.data.message;
    if (responseMessage === "User Found") {
      return true;
    }
    return false; // "User Not Found"
  } catch (error) {
    return false;
  }
};
